import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDashboard(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/tasks/dashboard')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/tasks', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id, recordType}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/tasks/view/${id}?recordType=${recordType}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/tasks/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/tasks', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/tasks', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/tasks/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/tasks/cancel', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        processingItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/tasks/processing/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        closedItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/tasks/closed/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        commentItem(ctx, {id, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/tasks/comments/${id}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        redirectItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/tasks/redirect', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
