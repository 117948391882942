<template>
  <b-modal
      no-close-on-backdrop
      hide-header-close
      centered
      scrollable
      ok-variant="dark"
      :title="dataInfo.id === 0 ? 'Destek Kaydı Ekle' : 'Destek Kaydı Değiştir'"
      modal-class="modal-dark"
      v-model="isTaskNewSidebarActive"
      id="task-new-record-sidebar"
      size="lg"
      hide-footer
      v-if="isTaskNewSidebarActive"
      no-close-on-esc>
    <template #default="{ hide }">

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-0" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <b-row>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Türü" rules="required">
                <b-form-group label="* Türü" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.taskTypeId" :options="taskTypeOptions" :reduce="val => val.value" :clearable="false"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Öncelik" rules="required">
                <b-form-group label="* Öncelik" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.taskPriorityStatusId" :options="taskPriorityStatusOptions" :reduce="val => val.value" :clearable="false"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Departman" rules="required">
                <b-form-group label="* Departman" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.organizationDepartmentId" :options="organizationDepartmentOptions" :reduce="val => val.value" :clearable="false" v-on:input="getOrganizationUnits"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Birim" rules="required">
                <b-form-group label="* Birim" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.organizationUnitId" :options="organizationUnitOptions" :reduce="val => val.value" :clearable="false" v-on:input="getTaskCategories"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Kategori" rules="required">
                <b-form-group label="* Kategori" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.taskCategoryId" :options="taskCategoryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getTaskDefinitions"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Konu" rules="required">
                <b-form-group label="* Konu" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.taskDefinitionId" :options="taskDefinitionOptions" :reduce="val => val.value" :clearable="false"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <validation-provider #default="validationContext" name="Açıklama" rules="required">
            <b-form-group label="* Açıklama">
              <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="3"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Etiketlenen Kişiler">
            <b-form-group label="Etiketlenen Kişiler" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.taggedUsers" :clearable="false" :options="taggedUserOptions" label="label" :reduce="val => val.value" multiple="multiple">
                <template #option="{ avatar, label }">
                  <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
                <template #selected-option="{ avatar, label }">
                  <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Döküman" v-if="props.recordDataId == 0">
            <b-form-group label="Döküman" description="Birden fazla döküman seçimi yapabilirsiniz...">
              <b-form-file id="taskFiles" name="taskFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
            </b-form-group>
          </validation-provider>

          <div class="d-flex border-top pt-1">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="submit">
              <feather-icon icon="SaveIcon" size="16"/>
              <span class="align-middle"> Kaydet</span>
            </b-button>
            <b-button @click="$emit('update:is-task-new-sidebar-active', false)" variant="outline-secondary">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Vazgeç</span>
            </b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile, BAvatar, BModal, BCol, BRow
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {onUnmounted, ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import definitionModule from "@/views/system/definition/store";
import userModule from "@/views/organization/employee/store"
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import flatpickr from "vue-flatpickr-component/src/component"
import {avatarText, getApiFile} from "@core/utils/filter"

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BAvatar,
    BFormFile,
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,
    flatpickr,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isTaskNewSidebarActive',
    event: 'update:is-task-new-sidebar-active',
  },
  props: {
    isTaskNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_DEFINITION_MODULE_NAME)) {
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_DEFINITION_MODULE_NAME)) {
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      taskTypeId: null,
      organizationDepartmentId: null,
      organizationUnitId: null,
      taskCategoryId: null,
      taskDefinitionId: null,
      description: '',
      taskPriorityStatusId: null,
      taggedUsers: []
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const defaultTaskType = ref(null)

    const taskTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'TASK_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        taskTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.taskTypeId = value.key
          defaultTaskType.value = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const taskPriorityStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'TASK_PRIORITY_STATUS'}).then(response => {
      response.data.data.forEach((value, index) => {
        taskPriorityStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.taskPriorityStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const organizationDepartmentOptions = ref([])
    busy.value = true
    store.dispatch('store-organization/fetchDepartmentItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          organizationDepartmentOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const organizationUnitOptions = ref([])
    const getOrganizationUnits = (val) => {
      organizationUnitOptions.value = []
      if (val != null) {
        busy.value = true
        store.dispatch('store-organization/fetchDepartmentUnits', {departmentId: val}).then(response => {
          response.data.data.forEach((value, index) => {
            if (value.isActive) {
              organizationUnitOptions.value.push({label: value.name, value: value.id})
            }
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.organizationUnitId = null
      }
    }

    const taskCategoryOptions = ref([])
    const taskDefinitionOptions = ref([])
    const taggedUserOptions = ref([])

    const getTaskCategories = (val) => {
      taskCategoryOptions.value = []
      taskDefinitionOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-task-category/fetchItems', {organizationUnitId: val, showOnTask: true}).then(response => {
          response.data.data.forEach((value, index) => {
            if (value.isActive) {
              taskCategoryOptions.value.push({label: value.name, value: value.id})
            }
          });


          //TaggedUsers
          busy.value = true
          store.dispatch('store-user/fetchItems', {
            sortColumn: 'firstName',
            sortDesc: false,
            isActive: true,
          }).then(response => {
            response.data.data.forEach((value, index) => {
              if (value.organizationId !== val) {
                if (!taggedUserOptions.value.some(e => e.value === value.id)) {
                  taggedUserOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
                }
              }
            })
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })


        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.taskCategoryId = null
      }
    }

    const getTaskDefinitions = (val) => {
      taskDefinitionOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-task-category/fetchDefinitionList', {taskCategoryId: val, queryParams: {showOnTask: true}}).then(response => {
          response.data.data.forEach((value, index) => {
            if (value.isActive) {
              taskDefinitionOptions.value.push({label: value.name, value: value.id})
            }
          })
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.taskDefinitionId = null
      }
    }

    const onSubmit = () => {
      const formData = new FormData();

      if (props.recordDataId === 0) {
        const documents = document.getElementById('taskFiles').files
        for (let i = 0; i < documents.length; i++) {
          let file = documents[i];
          formData.append('documents[' + i + ']', file);
        }
      }

      formData.append('id', dataInfo.value.id);
      formData.append('taskTypeId', dataInfo.value.taskTypeId);
      formData.append('organizationDepartmentId', dataInfo.value.organizationDepartmentId);
      formData.append('organizationUnitId', dataInfo.value.organizationUnitId);
      formData.append('taskCategoryId', dataInfo.value.taskCategoryId);
      formData.append('taskDefinitionId', dataInfo.value.taskDefinitionId);
      formData.append('description', dataInfo.value.description);
      formData.append('taskPriorityStatusId', dataInfo.value.taskPriorityStatusId);

      for (const idKey in dataInfo.value.taggedUsers) {
        formData.append('taggedUsers', dataInfo.value.taggedUsers[idKey]);
      }

      busy.value = true
      store.dispatch('store/' + (props.recordDataId > 0 ? 'editItem' : 'addItem'), formData).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-task-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isTaskNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
          if (defaultTaskType.value !== null) {
            dataInfo.value.taskTypeId = defaultTaskType.value
          }
        } else {
          busy.value = true
          store.dispatch('store/fetchItem', {id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
            getOrganizationUnits(dataInfo.value.organizationDepartmentId)
            getTaskCategories(dataInfo.value.organizationUnitId)
            getTaskDefinitions(dataInfo.value.taskCategoryId)
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-task-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      taskTypeOptions,
      taskPriorityStatusOptions,
      organizationDepartmentOptions,
      organizationUnitOptions,
      taskCategoryOptions,
      taskDefinitionOptions,
      taggedUserOptions,
      props,

      avatarText,
      getApiFile,

      getValidationState,
      resetForm,
      onSubmit,
      getOrganizationUnits,
      getTaskCategories,
      getTaskDefinitions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#task-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
