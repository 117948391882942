<template>
  <div>
    <TaskAdd :is-task-new-sidebar-active.sync="isTaskNewSidebarActive" :record-data-id="taskRecordDataId"></TaskAdd>
    <b-card no-body>

      <div class="d-flex p-1 border-bottom">
        <h3 class="mb-0 ml-50">Giden Görevler</h3>
      </div>

      <b-row>
        <b-col cols="12" md="3" lg="3" class="pr-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Yeni Görev</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link @click="isTaskNewSidebarActive = true; taskRecordDataId=0">
                <feather-icon icon="PlusCircleIcon" size="64"/>
              </b-link>
            </div>

            <b-card-body class="pl-0" @click="isTaskNewSidebarActive = true; taskRecordDataId=0">
              <small>Yeni destek kaydı...</small>
            </b-card-body>

            <b-button variant="primary" @click="isTaskNewSidebarActive = true; taskRecordDataId=0">
              <span>Yeni Görev</span>
            </b-button>

          </b-card>
        </b-col>
        <b-col cols="12" md="3" lg="3" class="pr-0 pl-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Bekleyen Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'WAITING'}}">
                <feather-icon icon="GridIcon" size="64" class="text-warning"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Cevap bekleyen görevleriniz...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'WAITING'}}">
              <span>Bekleyen Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.outgoingWaitingCount > 0">
                {{ dataInfo.outgoingWaitingCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
        <b-col cols="12" md="3" lg="3" class="pr-0 pl-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Cevaplanmış Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'ANSWERED'}}">
                <feather-icon icon="MessageSquareIcon" size="64" class="text-danger"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Devam eden görevleriniz...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'ANSWERED'}}">
              <span>Cevaplanmış Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.outgoingAnsweredCount > 0">
                {{ dataInfo.outgoingAnsweredCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
        <b-col cols="12" md="3" lg="3" class="pl-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Kapanmış Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'CLOSED'}}">
                <feather-icon icon="CheckSquareIcon" size="64" class="text-success"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Kapanmış görevleriniz...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'OUTGOING', statusType: 'CLOSED'}}">
              <span>Kapanmış Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.outgoingClosedCount > 0">
                {{ dataInfo.outgoingClosedCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
      </b-row>

    </b-card>
    <b-card no-body>

      <div class="d-flex p-1 border-bottom">
        <h3 class="mb-0 ml-50">Gelen Görevler</h3>
      </div>

      <b-row>
        <b-col cols="12" md="3" lg="3" class="pr-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Bekleyen Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'WAITING'}}">
                <feather-icon icon="GridIcon" size="64" class="text-warning"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Biriminizdeki bekleyen görevler...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'WAITING'}}">
              <span>Bekleyen Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.incomingWaitingCount > 0">
                {{ dataInfo.incomingWaitingCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
        <b-col cols="12" md="3" lg="3" class="pr-0 pl-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Cevaplanmış Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'ANSWERED'}}">
                <feather-icon icon="MessageSquareIcon" size="64" class="text-danger"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Biriminizdeki devam eden görevler...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'ANSWERED'}}">
              <span>Cevaplanmış Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.incomingAnsweredCount > 0">
                {{ dataInfo.incomingAnsweredCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
        <b-col cols="12" md="3" lg="3" class="pl-0">
          <b-card class="p-1 m-1 border task-card" no-body>
            <b-card-header class="p-0">
              <b-card-title>Kapanmış Görevler</b-card-title>
            </b-card-header>

            <div class="item-img text-left pt-2">
              <b-link :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'CLOSED'}}">
                <feather-icon icon="CheckSquareIcon" size="64" class="text-success"/>
              </b-link>
            </div>

            <b-card-body class="pl-0">
              <small>Biriminizdeki kapanmış görevler...</small>
            </b-card-body>

            <b-button variant="primary" :to="{name: 'task-list', params: {recordType: 'INCOMING', statusType: 'CLOSED'}}">
              <span>Kapanmış Görevler</span>
              <b-badge pill variant="warning" class="text-capitalize ml-1" v-if="dataInfo.incomingClosedCount > 0">
                {{ dataInfo.incomingClosedCount }}
              </b-badge>
            </b-button>

          </b-card>
        </b-col>
      </b-row>

    </b-card>
    <Overlay :busy="busy"></Overlay>
  </div>
</template>

<script>
import {onUnmounted, ref, watch} from '@vue/composition-api'
import store from '@/store'
import storeModule from './store'
import taskCategoryModule from "@/views/definition/taskcategory/store";
import organizationModule from "@/views/organization/organization/store"
import Overlay from "@/components/Overlay.vue";
import TaskAdd from "@/views/task/Add.vue";
import {BBadge, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BLink, BRow} from "bootstrap-vue";
import {toastMessage} from "@core/utils/utils";
import router from "@/router";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BBadge,
    BButton,
    BCardBody,
    BLink,
    BCardTitle,
    BCardHeader,
    BCol,
    BRow,
    BCard,

    Overlay,
    TaskAdd,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'
    const STORE_TASK_CATEGORY_MODULE_NAME = 'store-task-category'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
      store.registerModule(STORE_TASK_CATEGORY_MODULE_NAME, taskCategoryModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
        store.unregisterModule(STORE_TASK_CATEGORY_MODULE_NAME)
      }
    })

    const toast = useToast()

    const busy = ref(false)
    const isTaskNewSidebarActive = ref(false)
    const taskRecordDataId = ref(0)

    const dataInfo = ref({})

    const fetchList = () => {
      busy.value = true
      store.dispatch('store/fetchDashboard').then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'home'})
        }
      }).finally(() => {
        busy.value = false
      })
    }

    fetchList()

    watch([isTaskNewSidebarActive], () => {
      fetchList()
    })

    return {
      isTaskNewSidebarActive,
      taskRecordDataId,
      busy,
      dataInfo,

      fetchList
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.task-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }
}
</style>
